import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isAuthUser } from '../react-redux/actions/authAction';
import { logOutUser } from '../react-redux/actions/logOutAction';
import ProgressGrammar from '../grammar/ProgressGrammar';
import ViewAndChoose from '../review/ViewAndChoose';
import { Navigate } from 'react-router-dom';
import { reviewGrammar } from '../react-redux/actions/reviewAction';
import ReviewSuccessG from '../review/ReviewSuccessG';
import { grammarShow } from '../react-redux/actions/studyAction';
// import ViewAndChooseName from '../grammar/ViewAndChooseName';
import ViewAndFill from '../grammar/ViewAndFill';

class ReviewGrammar extends Component {
    componentDidMount() {
        this.props.isAuthUser();
        if (this.props.review.reviewGrammarData === null) {
            this.props.reviewGrammar();
        }
        if (this.props.study.grammarData === null) {
            this.props.grammarShow();
        }
    }

    logOutButton = () => {
        this.props.logOutUser();
    }
    constructor(props) {
        super(props);
        this.state = {
            numberHowtolearnNow: 0,
            numberGrammarReviewNow: 0,
            score: 0,
            completedGrammarReview: [],
            incorrectGrammarReview: [],
            incorrectCount: {},
            incorrectThree: []
        }
    }

    howToLearnNext = (grammarReview) => {
        if (this.props.review.reviewGrammarData !== null) {
            const { user } = this.props.auth;
            const grammarReviewId = this.props.review.reviewGrammarData.filter((value) => value.user_id === user.user_id);
            const grammarReviews = grammarReviewId.slice(0, 12);

            this.setState((prevState) => {
                const numberHowtolearnNext = prevState.numberHowtolearnNow + 1;
                const isLastHowtolearn = numberHowtolearnNext > 1;
                let numberGrammarReviewNext = prevState.numberGrammarReviewNow;
                if (isLastHowtolearn) {
                    let potentialNext = prevState.numberGrammarReviewNow + 1;
                    const grammarReviewsLength = grammarReviews.length;
                    while (prevState.completedGrammarReview.includes(potentialNext % grammarReviewsLength)) {
                        potentialNext += 1;
                    }
                    numberGrammarReviewNext = potentialNext % grammarReviewsLength;
                }
                const numberHowtolearnReset = isLastHowtolearn ? 0 : numberHowtolearnNext;

                // Tách biệt logic đánh dấu từ đã hoàn thành và từ chưa hoàn thành
                const completedGrammarReview = [...prevState.completedGrammarReview];
                const incorrectGrammarReview = [...prevState.incorrectGrammarReview];
                const incorrectCount = { ...prevState.incorrectCount };

                if (isLastHowtolearn && !completedGrammarReview.includes(prevState.numberGrammarReviewNow)) {
                    completedGrammarReview.push(prevState.numberGrammarReviewNow);
                    const incorrectIndex = incorrectGrammarReview.indexOf(prevState.numberGrammarReviewNow);
                    if (incorrectIndex > -1) {
                        incorrectGrammarReview.splice(incorrectIndex, 1);
                    }
                }

                // Kiểm tra nếu đã đến cuối danh sách ngữ pháp
                if (numberGrammarReviewNext >= grammarReviews.length) {
                    // Nếu còn ngữ pháp sai thì quay lại từ sai đầu tiên
                    if (prevState.incorrectGrammarReview.length > 0) {
                        return {
                            numberHowtolearnNow: 0,
                            numberGrammarReviewNow: prevState.incorrectGrammarReview[0], // Quay lại từ sai đầu tiên
                            score: prevState.score + (100 / (grammarReview * 2)),
                            completedGrammarReview,
                            incorrectGrammarReview,
                            incorrectCount,
                        };
                    } else {
                        return {
                            numberHowtolearnNow: -1,
                            completedGrammarReview,
                            score: prevState.score + (100 / (grammarReview * 2)),
                        }; // Kết thúc nếu không còn ngữ pháp để ôn lại
                    }
                } else {
                    return {
                        numberHowtolearnNow: numberHowtolearnReset,
                        numberGrammarReviewNow: numberGrammarReviewNext,
                        score: prevState.score + (100 / (grammarReview * 2)),
                        completedGrammarReview,
                        incorrectGrammarReview,
                        incorrectCount,
                    };
                }
            });
        }
    }

    howToLearnBackChoose = (grammarReview) => {
        if (this.props.review.reviewGrammarData !== null) {
            const { user } = this.props.auth;
            const grammarReviewId = this.props.review.reviewGrammarData.filter((value) => value.user_id === user.user_id);
            const grammarReviews = grammarReviewId.slice(0, 12);

            this.setState((prevState) => {
                const { numberGrammarReviewNow, incorrectGrammarReview, completedGrammarReview, incorrectThree } = prevState;

                // Sao chép incorrectCount để cập nhật và kiểm tra số lần sai
                const incorrectCount = { ...prevState.incorrectCount };

                // Tăng số lần sai cho từ hiện tại
                incorrectCount[numberGrammarReviewNow] = (incorrectCount[numberGrammarReviewNow] || 0) + 1;

                // Nếu số lần sai là 2, đặt numberHowtolearnNow = 0 và giữ nguyên từ hiện tại
                if (incorrectCount[numberGrammarReviewNow] === 2) {
                    const updatedIncorrectThree = [...incorrectThree, numberGrammarReviewNow];
                    return {
                        numberHowtolearnNow: 0,
                        numberGrammarReviewNow,
                        score: prevState.score,
                        incorrectGrammarReview,
                        incorrectCount,
                        incorrectThree: updatedIncorrectThree,
                    };
                } else if (incorrectCount[numberGrammarReviewNow] === 3) {

                    const updatedCompletedGrammarReview = [...completedGrammarReview, numberGrammarReviewNow];

                    // Xóa từ này khỏi incorrectGrammarReview để tránh học lại
                    const updatedIncorrectGrammarReview = incorrectGrammarReview.filter(item => item !== numberGrammarReviewNow);

                    // Chuyển sang từ sai tiếp theo chưa hoàn thành trong `incorrectGrammarReview`
                    const nextIncorrectGrammarReview = updatedIncorrectGrammarReview.find(
                        item => !updatedCompletedGrammarReview.includes(item)
                    );

                    return {
                        numberHowtolearnNow: 0,
                        numberGrammarReviewNow: nextIncorrectGrammarReview,
                        score: prevState.score + ((100 / (grammarReview * 2) * 2)),
                        incorrectGrammarReview: updatedIncorrectGrammarReview,
                        completedGrammarReview: updatedCompletedGrammarReview,
                        incorrectCount,
                    };
                }

                // Nếu chỉ sai lần đầu, thêm vào `incorrectVocabReview` nếu chưa có trong danh sách
                if (!incorrectGrammarReview.includes(numberGrammarReviewNow) && !completedGrammarReview.includes(numberGrammarReviewNow)) {
                    incorrectGrammarReview.push(numberGrammarReviewNow);
                }

                // Chọn từ tiếp theo để ôn lại, bỏ qua các từ đã hoàn thành
                let nextGrammarReviewToReview = (numberGrammarReviewNow + 1) % grammarReviews.length;
                while (completedGrammarReview.includes(nextGrammarReviewToReview)) {
                    nextGrammarReviewToReview = (nextGrammarReviewToReview + 1) % grammarReviews.length;
                }

                return {
                    numberHowtolearnNow: 0,
                    numberGrammarReviewNow: nextGrammarReviewToReview,
                    score: prevState.score,
                    incorrectGrammarReview,
                    incorrectCount,
                };
            });
        }
    };

    howToLearnBackChooseName = (grammarReview) => {
        this.setState((prevState) => ({
            numberHowtolearnNow: 0,
            score: prevState.score - (100 / (grammarReview * 2))
        }));
    }

    howToLearn = (user_id) => {
        if (this.props.review.reviewGrammarData !== null) {
            const grammarReviewId = this.props.review.reviewGrammarData.filter((value) => value.user_id === user_id);
            const grammarReview = grammarReviewId.slice(0, 12);
            const { numberHowtolearnNow, numberGrammarReviewNow, completedGrammarReview, incorrectThree } = this.state;
            if (completedGrammarReview.length >= grammarReview.length) {
                const completedGrammarReviewfind = grammarReview.filter((grammar, index) => !incorrectThree.includes(index));
                const incorrectThreeFind = grammarReview.filter((vocab, index) => incorrectThree.includes(index));
                return (
                    <ReviewSuccessG grammarReview = {grammarReview} completedReview={completedGrammarReviewfind} incorrectThree={incorrectThreeFind} user_id={user_id} linkto={'/homeMember/'} />
                )
            }
            const reviewGrammarNow = grammarReview[numberGrammarReviewNow];
            switch (numberHowtolearnNow) {
                case 0:
                    return <ViewAndChoose key={`${numberGrammarReviewNow}-${numberHowtolearnNow}`} mean={reviewGrammarNow.mean} name={reviewGrammarNow.name} example={reviewGrammarNow.example} mean_example={reviewGrammarNow.mean_example} explain={reviewGrammarNow.explain} grammar_id={reviewGrammarNow.grammar_id} howToLearnNext={() => this.howToLearnNext(grammarReview.length)} howToLearnBackChoose={() => this.howToLearnBackChoose(grammarReview.length)} />
                case 1:
                    return <ViewAndFill key={`${numberGrammarReviewNow}-${numberHowtolearnNow}`} grammar_id={reviewGrammarNow.grammar_id} sound={reviewGrammarNow.sound} name={reviewGrammarNow.name} example={reviewGrammarNow.example} mean={reviewGrammarNow.mean} mean_example={reviewGrammarNow.mean_example} explain={reviewGrammarNow.explain} howToLearnNext={() => this.howToLearnNext(grammarReview.length)} howToLearnBackChooseName={() => this.howToLearnBackChooseName(grammarReview.length)} />
                default:
                    break;
            }
        }
    }

    render() {
        const { user } = this.props.auth;
        const { isNavigateLogOut } = this.props.logOut;
        if (isNavigateLogOut) {
            return <Navigate to="/login" />
        }
        return (
            <main>
                <div className="content">
                    <div className="container">
                        <div className="studyGrammar">
                            <ProgressGrammar linkto={'/homeMember'} score={this.state.score} />
                            {/* end progress_grammar */}
                            <div className="studyGrammarLesson">
                                <div className="howToLearn">
                                    {this.howToLearn(user.user_id)}
                                </div>
                                {/*end howToLearn */}
                            </div>
                            {/* end studyGrammarLesson */}
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        logOut: state.logOut,
        review: state.review,
        study: state.study
    }
}
const mapDispatchToProps = {
    isAuthUser,
    logOutUser,
    reviewGrammar,
    grammarShow
}
export default connect(mapStateToProps, mapDispatchToProps)(ReviewGrammar);