import React, { Component } from 'react';
import { connect } from 'react-redux';
import AlertSuccessStudy from '../vocabulary/AlertSuccessStudy';
import AlertErrorStudy from '../vocabulary/AlertErrorStudy';

class ListenAndChoose extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectOption: '',
            optionValue: this.takeOptions(),
            showAlertStudy: '',
            isDisabled: false,
        }
    }
    componentDidMount() {
        document.querySelector('.fa-solid.fa-volume-high').addEventListener('click', function () {
            var soundCard = document.querySelector('.audios');
            soundCard.play();
            this.classList.toggle('scale');
            this.addEventListener("webkitAnimationEnd", function () {
                this.classList.remove('scale');
            });
        });

        const optionChoose = document.querySelectorAll('.optionChoose');
        optionChoose.forEach((option) => {
            option.onclick = function () {
                optionChoose.forEach((opt) => opt.classList.remove('optionChooseColor'));
                this.classList.add('optionChooseColor');
            };
        });

    }
    takeOptions = () => {
        const { vocabularyData } = this.props.study;
        const correctOptions = this.props.vocab_id;
        const otherOptions = vocabularyData.map(value => value.vocab_id).filter(vocab_id => vocab_id !== correctOptions);
        // console.log(otherOptions);
        const randomOptions = this.shuffle(otherOptions).slice(0, 3);
        // console.log(randomOptions);
        const allOptions = this.shuffle([...randomOptions, correctOptions]);
        return allOptions;
    }
    shuffle = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
    clickOptionChoose = (option) => {
        this.setState({
            selectOption: option
        });
        const correctOptions = this.props.vocab_id;
        if (correctOptions === option) {
            this.setState({
                showAlertStudy: 'success',
                isDisabled: true
            });
        } else {
            this.setState({
                showAlertStudy: 'error',
                isDisabled: true
            });
        }
    }

    clickCheck = () => {
        const correctOptions = this.props.vocab_id;
        const { selectOption } = this.state;
        if (correctOptions === selectOption) {
            this.setState({
                showAlertStudy: 'success',
                isDisabled: true
            });
        } else {
            this.setState({
                showAlertStudy: 'error',
                isDisabled: true
            });
        }
    }

    render() {
        const { vocabularyData } = this.props.study;
        const meanOptionById = (id) => {
            const meanOption = vocabularyData.find(value => value.vocab_id === id);
            return meanOption ? meanOption.mean : '';
        }
        return (
            <div className="listenAndChoose">
                <h2>Hãy nghe và chọn đáp án</h2>
                <div className="sound_listen">
                    <audio className="audios" autoPlay>
                        <source src={this.props.sound} type="audio/mpeg" />
                    </audio>
                    <i className="fa-solid fa-volume-high" />
                </div>
                <div className="optionChooseALL">
                    {this.state.optionValue.map(value => (
                        <button key={value} type="button" className="btn btn-primary optionChoose" onClick={() => this.clickOptionChoose(value)} disabled={this.state.isDisabled}>
                            {meanOptionById(value)}
                        </button>
                    ))}
                </div>
                <button name="" id="" className="btn btn-primary check" onClick={this.clickCheck} disabled={this.state.isDisabled}>
                    kiểm tra
                </button>
                {this.state.showAlertStudy === 'success' && <AlertSuccessStudy name={this.props.name} pronunciation={this.props.pronunciation} mean={this.props.mean} example={this.props.example} example_mean={this.props.example_mean} sound={this.props.sound} howtolearnNext={this.props.howToLearnNext} />}
                {this.state.showAlertStudy === 'error' && <AlertErrorStudy name={this.props.name} pronunciation={this.props.pronunciation} mean={this.props.mean} example={this.props.example} example_mean={this.props.example_mean} sound={this.props.sound} howToLearnBack={this.props.howToLearnBackChoose} nameButton={'Học lại'} sino_vietnamese_sound = {this.props.sino_vietnamese_sound}/>}
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        study: state.study
    }
}
export default connect(mapStateToProps)(ListenAndChoose)