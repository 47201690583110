import React, { Component } from 'react';
import AlertSuccessStudy from './AlertSuccessStudy';
import AlertErrorStudy from './AlertErrorStudy';
import TinySegmenter from 'tiny-segmenter';

class ViewAndFill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedWords: [],
            shuffledWordsExample: [],
            showAlertStudy: '',
            isDisabled: false,
        };
    }
    componentDidMount() {
        this.shuffledWordsExample();
    }

    shuffledWordsExample = () => {
        const segmenter = new TinySegmenter();
        const exampleSegments = segmenter.segment(this.props.example);
        // console.log(exampleSegments);
        const filteredExample = exampleSegments.filter((word) => word.trim() !== "");
        // console.log(filteredExample);
        const shuffled = filteredExample
            .map((word) => ({ word, random: Math.random() }))
            .sort((a, b) => a.random - b.random)
            .map((item) => item.word);

        this.setState({ shuffledWordsExample: shuffled });
    };
    // Chọn từ từ shuffledWords và thêm vào selectedWords
    handleWordClick = (word) => {
        this.setState((prevState) => {
            const shuffledWordsExample = [...prevState.shuffledWordsExample];
            const index = shuffledWordsExample.indexOf(word); // Tìm vị trí của từ
            if (index > -1) {
                shuffledWordsExample.splice(index, 1); // Xóa từ tại vị trí tìm được
            }
            return {
                shuffledWordsExample,
                selectedWords: [...prevState.selectedWords, word], // Thêm từ vào selectedWords
            };
        });
    };

    // Chọn từ từ selectedWords và thêm lại vào shuffledWords
    handleSelectedWordClick = (word) => {
        this.setState((prevState) => {
            const selectedWords = [...prevState.selectedWords];
            const index = selectedWords.indexOf(word); // Tìm vị trí của từ
            if (index > -1) {
                selectedWords.splice(index, 1); // Xóa từ tại vị trí tìm được
            }
            return {
                selectedWords,
                shuffledWordsExample: [...prevState.shuffledWordsExample, word], // Thêm từ vào shuffledWords
            };
        });
    };

    clickCheck = () => {
        const segmenter = new TinySegmenter();
        const correctAnswerSegments = segmenter.segment(this.props.example);
        const correctAnswer = correctAnswerSegments.filter((word) => word.trim() !== "");
        const correctAnswerJoin = correctAnswer.join('');
        const { selectedWords } = this.state;
        const selectedWordsJoin = selectedWords.join('');
        // console.log(selectedWordsJoin);
        // console.log(correctAnswerJoin);
        if (selectedWordsJoin === correctAnswerJoin) {
            this.setState({
                showAlertStudy: 'success',
                isDisabled: true
            });
        } else {
            this.setState({
                showAlertStudy: 'error',
                isDisabled: true
            });
        }
    };
    render() {
        const { selectedWords, shuffledWordsExample } = this.state;
        return (
            <div className="viewAndChoose">
                <h2>Nối chữ thành câu ví dụ</h2>
                <div className="view_grammar">{this.props.mean_example}</div>
                <div className='showChoose'>
                    {selectedWords.map((word, index) => (
                        <button key={index} className='showChooseButton' onClick={() => this.handleSelectedWordClick(word)}>
                            {word}
                        </button>
                    ))}
                </div>
                <div className="line"></div>
                <div className='shuffledWordsExample'>
                    {shuffledWordsExample.map((word, index) => (
                        <button
                            key={index}
                            className='showButtonWords'
                            onClick={() => this.handleWordClick(word)}
                        >
                            {word}
                        </button>
                    ))}
                </div>
                <button className="btn btn-primary check" onClick={this.clickCheck} disabled={this.state.isDisabled}>
                    kiểm tra
                </button>
                {this.state.showAlertStudy === 'success' && <AlertSuccessStudy name={this.props.name} mean={this.props.mean} example={this.props.example} mean_example={this.props.mean_example} explain={this.props.explain} howToLearnNext={this.props.howToLearnNext} />}
                {this.state.showAlertStudy === 'error' && <AlertErrorStudy name={this.props.name} mean={this.props.mean} example={this.props.example} mean_example={this.props.mean_example} explain={this.props.explain} howToLearnBack={this.props.howToLearnBackChooseName} nameButton={'Quay lại'} />}
            </div>
        );
    }
}

export default ViewAndFill;