import React, { Component } from 'react';
import MenuHome from './MenuHome';
import { connect } from 'react-redux';
import { isAuthUser } from '../react-redux/actions/authAction';
import { logOutUser } from '../react-redux/actions/logOutAction';
import { Navigate } from 'react-router-dom';
import NoReview from '../review/NoReview';
import ContentReview from '../review/ContentReview';
import { showReview, showReviewCountAll, showReviewCountFive, showReviewCountFour, showReviewCountOne, showReviewCountThree, showReviewCountTwo } from '../react-redux/actions/reviewAction';
import { grammarShow, vocabularyShow } from '../react-redux/actions/studyAction';
import { alertDOnSuccess } from '../react-redux/actions/alertAction';
import AlertDanger from '../alerts/AlertDanger';
import InstructionSignUpMember from '../alerts/InstructionSignUpMember';
import { timeStudy } from '../react-redux/actions/signUpMemberAction';
import NoHaveDiscountPercent from '../signUpMember/NoHaveDiscountPercent';
import HaveDiscountPercent from '../signUpMember/HaveDiscountPercent';

class Home extends Component {

    componentDidMount() {
        this.props.isAuthUser();
        if (this.props.review.reviewData === null) {
            this.props.showReview();
        }
        if (this.props.review.reviewCountAllData === null) {
            this.props.showReviewCountAll();
        }
        if (this.props.review.reviewCountOneData === null) {
            this.props.showReviewCountOne();
        }
        if (this.props.review.reviewCountTwoData === null) {
            this.props.showReviewCountTwo();
        }
        if (this.props.review.reviewCountThreeData === null) {
            this.props.showReviewCountThree();
        }
        if (this.props.review.reviewCountFourData === null) {
            this.props.showReviewCountFour();
        }
        if (this.props.review.reviewCountFiveData === null) {
            this.props.showReviewCountFive();
        }
        if (this.props.study.vocabularyData === null) {
            this.props.vocabularyShow();
        }
        if (this.props.study.grammarData === null) {
            this.props.grammarShow();
        }
        if (this.props.signUpMember.timeStudyData === null) {
            this.props.timeStudy()
        }
    }

    logOutButton = () => {
        this.props.logOutUser();
    }

    showReview = (user_id) => {
        if (this.props.review.reviewData !== null && this.props.review.reviewCountAllData !== null && this.props.review.reviewCountOneData !== null && this.props.review.reviewCountTwoData !== null && this.props.review.reviewCountThreeData !== null && this.props.review.reviewCountFourData !== null && this.props.review.reviewCountFiveData !== null) {
            const findUserReview = this.props.review.reviewData.filter(value => value.user_id === user_id);
            const findUserReviewCountAll = this.props.review.reviewCountAllData.filter(value => value.user_id === user_id);
            const findUserReviewCountOne = this.props.review.reviewCountOneData.filter(value => value.user_id === user_id);
            const findUserReviewCountTwo = this.props.review.reviewCountTwoData.filter(value => value.user_id === user_id);
            const findUserReviewCountThree = this.props.review.reviewCountThreeData.filter(value => value.user_id === user_id);
            const findUserReviewCountFour = this.props.review.reviewCountFourData.filter(value => value.user_id === user_id);
            const findUserReviewCountFive = this.props.review.reviewCountFiveData.filter(value => value.user_id === user_id);
            if (findUserReview.length > 0) {
                // zero
                if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length > 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={findUserReviewCountOne[0].countvocabone} countGrammarOne={findUserReviewCountOne[0].countgrammarone} countVocabTwo={findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo={findUserReviewCountTwo[0].countgrammartwo} countVocabThree={findUserReviewCountThree[0].countvocabthree} countGrammarThree={findUserReviewCountThree[0].countgrammarthree} countVocabFour={findUserReviewCountFour[0].countvocabfour} countGrammarFour={findUserReviewCountFour[0].countgrammarfour} countVocabFive={findUserReviewCountFive[0].countvocabfive} countGrammarFive={findUserReviewCountFive[0].countgrammarfive} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                }
                //one
                else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length > 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={0} countGrammarOne={0} countVocabTwo={findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo={findUserReviewCountTwo[0].countgrammartwo} countVocabThree={findUserReviewCountThree[0].countvocabthree} countGrammarThree={findUserReviewCountThree[0].countgrammarthree} countVocabFour={findUserReviewCountFour[0].countvocabfour} countGrammarFour={findUserReviewCountFour[0].countgrammarfour} countVocabFive={findUserReviewCountFive[0].countvocabfive} countGrammarFive={findUserReviewCountFive[0].countgrammarfive} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length > 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={findUserReviewCountOne[0].countvocabone} countGrammarOne={findUserReviewCountOne[0].countgrammarone} countVocabTwo={0} countGrammarTwo={0} countVocabThree={findUserReviewCountThree[0].countvocabthree} countGrammarThree={findUserReviewCountThree[0].countgrammarthree} countVocabFour={findUserReviewCountFour[0].countvocabfour} countGrammarFour={findUserReviewCountFour[0].countgrammarfour} countVocabFive={findUserReviewCountFive[0].countvocabfive} countGrammarFive={findUserReviewCountFive[0].countgrammarfive} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length > 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={findUserReviewCountOne[0].countvocabone} countGrammarOne={findUserReviewCountOne[0].countgrammarone} countVocabTwo={findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo={findUserReviewCountTwo[0].countgrammartwo} countVocabThree={0} countGrammarThree={0} countVocabFour={findUserReviewCountFour[0].countvocabfour} countGrammarFour={findUserReviewCountFour[0].countgrammarfour} countVocabFive={findUserReviewCountFive[0].countvocabfive} countGrammarFive={findUserReviewCountFive[0].countgrammarfive} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length > 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={findUserReviewCountOne[0].countvocabone} countGrammarOne={findUserReviewCountOne[0].countgrammarone} countVocabTwo={findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo={findUserReviewCountTwo[0].countgrammartwo} countVocabThree={findUserReviewCountThree[0].countvocabthree} countGrammarThree={findUserReviewCountThree[0].countgrammarthree} countVocabFour={0} countGrammarFour={0} countVocabFive={findUserReviewCountFive[0].countvocabfive} countGrammarFive={findUserReviewCountFive[0].countgrammarfive} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length === 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={findUserReviewCountOne[0].countvocabone} countGrammarOne={findUserReviewCountOne[0].countgrammarone} countVocabTwo={findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo={findUserReviewCountTwo[0].countgrammartwo} countVocabThree={findUserReviewCountThree[0].countvocabthree} countGrammarThree={findUserReviewCountThree[0].countgrammarthree} countVocabFour={findUserReviewCountFour[0].countvocabfour} countGrammarFour={findUserReviewCountFour[0].countgrammarfour} countVocabFive={0} countGrammarFive={0} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                }
                //two 
                else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length > 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={0} countGrammarOne={0} countVocabTwo={0} countGrammarTwo={0} countVocabThree={findUserReviewCountThree[0].countvocabthree} countGrammarThree={findUserReviewCountThree[0].countgrammarthree} countVocabFour={findUserReviewCountFour[0].countvocabfour} countGrammarFour={findUserReviewCountFour[0].countgrammarfour} countVocabFive={findUserReviewCountFive[0].countvocabfive} countGrammarFive={findUserReviewCountFive[0].countgrammarfive} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length > 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={0} countGrammarOne={0} countVocabTwo={findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo={findUserReviewCountTwo[0].countgrammartwo} countVocabThree={0} countGrammarThree={0} countVocabFour={findUserReviewCountFour[0].countvocabfour} countGrammarFour={findUserReviewCountFour[0].countgrammarfour} countVocabFive={findUserReviewCountFive[0].countvocabfive} countGrammarFive={findUserReviewCountFive[0].countgrammarfive} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length > 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={0} countGrammarOne={0} countVocabTwo={findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo={findUserReviewCountTwo[0].countgrammartwo} countVocabThree={findUserReviewCountThree[0].countvocabthree} countGrammarThree={findUserReviewCountThree[0].countgrammarthree} countVocabFour={0} countGrammarFour={0} countVocabFive={findUserReviewCountFive[0].countvocabfive} countGrammarFive={findUserReviewCountFive[0].countgrammarfive} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length === 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={0} countGrammarOne={0} countVocabTwo={findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo={findUserReviewCountTwo[0].countgrammartwo} countVocabThree={findUserReviewCountThree[0].countvocabthree} countGrammarThree={findUserReviewCountThree[0].countgrammarthree} countVocabFour={findUserReviewCountFour[0].countvocabfour} countGrammarFour={findUserReviewCountFour[0].countgrammarfour} countVocabFive={0} countGrammarFive={0} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length > 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={findUserReviewCountOne[0].countvocabone} countGrammarOne={findUserReviewCountOne[0].countgrammarone} countVocabTwo={0} countGrammarTwo={0} countVocabThree={0} countGrammarThree={0} countVocabFour={findUserReviewCountFour[0].countvocabfour} countGrammarFour={findUserReviewCountFour[0].countgrammarfour} countVocabFive={findUserReviewCountFive[0].countvocabfive} countGrammarFive={findUserReviewCountFive[0].countgrammarfive} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length > 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={findUserReviewCountOne[0].countvocabone} countGrammarOne={findUserReviewCountOne[0].countgrammarone} countVocabTwo={0} countGrammarTwo={0} countVocabThree={findUserReviewCountThree[0].countvocabthree} countGrammarThree={findUserReviewCountThree[0].countgrammarthree} countVocabFour={0} countGrammarFour={0} countVocabFive={findUserReviewCountFive[0].countvocabfive} countGrammarFive={findUserReviewCountFive[0].countgrammarfive} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length === 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={findUserReviewCountOne[0].countvocabone} countGrammarOne={findUserReviewCountOne[0].countgrammarone} countVocabTwo={0} countGrammarTwo={0} countVocabThree={findUserReviewCountThree[0].countvocabthree} countGrammarThree={findUserReviewCountThree[0].countgrammarthree} countVocabFour={findUserReviewCountFour[0].countvocabfour} countGrammarFour={findUserReviewCountFour[0].countgrammarfour} countVocabFive={0} countGrammarFive={0} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length > 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={findUserReviewCountOne[0].countvocabone} countGrammarOne={findUserReviewCountOne[0].countgrammarone} countVocabTwo={findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo={findUserReviewCountTwo[0].countgrammartwo} countVocabThree={0} countGrammarThree={0} countVocabFour={0} countGrammarFour={0} countVocabFive={findUserReviewCountFive[0].countvocabfive} countGrammarFive={findUserReviewCountFive[0].countgrammarfive} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length === 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={findUserReviewCountOne[0].countvocabone} countGrammarOne={findUserReviewCountOne[0].countgrammarone} countVocabTwo={findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo={findUserReviewCountTwo[0].countgrammartwo} countVocabThree={0} countGrammarThree={0} countVocabFour={findUserReviewCountFour[0].countvocabfour} countGrammarFour={findUserReviewCountFour[0].countgrammarfour} countVocabFive={0} countGrammarFive={0} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length === 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={findUserReviewCountOne[0].countvocabone} countGrammarOne={findUserReviewCountOne[0].countgrammarone} countVocabTwo={findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo={findUserReviewCountTwo[0].countgrammartwo} countVocabThree={findUserReviewCountThree[0].countvocabthree} countGrammarThree={findUserReviewCountThree[0].countgrammarthree} countVocabFour={0} countGrammarFour={0} countVocabFive={0} countGrammarFive={0} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                }
                //three
                else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length > 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={0} countGrammarOne={0} countVocabTwo={0} countGrammarTwo={0} countVocabThree={0} countGrammarThree={0} countVocabFour={findUserReviewCountFour[0].countvocabfour} countGrammarFour={findUserReviewCountFour[0].countgrammarfour} countVocabFive={findUserReviewCountFive[0].countvocabfive} countGrammarFive={findUserReviewCountFive[0].countgrammarfive} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length === 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={0} countGrammarOne={0} countVocabTwo={0} countGrammarTwo={0} countVocabThree={findUserReviewCountThree[0].countvocabthree} countGrammarThree={findUserReviewCountThree[0].countgrammarthree} countVocabFour={findUserReviewCountFour[0].countvocabfour} countGrammarFour={findUserReviewCountFour[0].countgrammarfour} countVocabFive={0} countGrammarFive={0} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length > 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={0} countGrammarOne={0} countVocabTwo={0} countGrammarTwo={0} countVocabThree={findUserReviewCountThree[0].countvocabthree} countGrammarThree={findUserReviewCountThree[0].countgrammarthree} countVocabFour={0} countGrammarFour={0} countVocabFive={findUserReviewCountFive[0].countvocabfive} countGrammarFive={findUserReviewCountFive[0].countgrammarfive} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length === 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={0} countGrammarOne={0} countVocabTwo={findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo={findUserReviewCountTwo[0].countgrammartwo} countVocabThree={findUserReviewCountThree[0].countvocabthree} countGrammarThree={findUserReviewCountThree[0].countgrammarthree} countVocabFour={0} countGrammarFour={0} countVocabFive={0} countGrammarFive={0} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length === 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={findUserReviewCountOne[0].countvocabone} countGrammarOne={findUserReviewCountOne[0].countgrammarone} countVocabTwo={findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo={findUserReviewCountTwo[0].countgrammartwo} countVocabThree={0} countGrammarThree={0} countVocabFour={0} countGrammarFour={0} countVocabFive={0} countGrammarFive={0} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length === 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={findUserReviewCountOne[0].countvocabone} countGrammarOne={findUserReviewCountOne[0].countgrammarone} countVocabTwo={0} countGrammarTwo={0} countVocabThree={findUserReviewCountThree[0].countvocabthree} countGrammarThree={findUserReviewCountThree[0].countgrammarthree} countVocabFour={0} countGrammarFour={0} countVocabFive={0} countGrammarFive={0} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length === 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={findUserReviewCountOne[0].countvocabone} countGrammarOne={findUserReviewCountOne[0].countgrammarone} countVocabTwo={0} countGrammarTwo={0} countVocabThree={0} countGrammarThree={0} countVocabFour={findUserReviewCountFour[0].countvocabfour} countGrammarFour={findUserReviewCountFour[0].countgrammarfour} countVocabFive={0} countGrammarFive={0} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length > 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={findUserReviewCountOne[0].countvocabone} countGrammarOne={findUserReviewCountOne[0].countgrammarone} countVocabTwo={0} countGrammarTwo={0} countVocabThree={0} countGrammarThree={0} countVocabFour={0} countGrammarFour={0} countVocabFive={findUserReviewCountFive[0].countvocabfive} countGrammarFive={findUserReviewCountFive[0].countgrammarfive} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length > 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={0} countGrammarOne={0} countVocabTwo={findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo={findUserReviewCountTwo[0].countgrammartwo} countVocabThree={0} countGrammarThree={0} countVocabFour={0} countGrammarFour={0} countVocabFive={findUserReviewCountFive[0].countvocabfive} countGrammarFive={findUserReviewCountFive[0].countgrammarfive} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length === 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={0} countGrammarOne={0} countVocabTwo={findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo={findUserReviewCountTwo[0].countgrammartwo} countVocabThree={0} countGrammarThree={0} countVocabFour={findUserReviewCountFour[0].countvocabfour} countGrammarFour={findUserReviewCountFour[0].countgrammarfour} countVocabFive={0} countGrammarFive={0} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                }
                //four
                else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length > 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={0} countGrammarOne={0} countVocabTwo={0} countGrammarTwo={0} countVocabThree={0} countGrammarThree={0} countVocabFour={0} countGrammarFour={0} countVocabFive={findUserReviewCountFive[0].countvocabfive} countGrammarFive={findUserReviewCountFive[0].countgrammarfive} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length > 0 && findUserReviewCountFive.length === 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={0} countGrammarOne={0} countVocabTwo={0} countGrammarTwo={0} countVocabThree={0} countGrammarThree={0} countVocabFour={findUserReviewCountFour[0].countvocabfour} countGrammarFour={findUserReviewCountFour[0].countgrammarfour} countVocabFive={0} countGrammarFive={0} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length > 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length === 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={0} countGrammarOne={0} countVocabTwo={0} countGrammarTwo={0} countVocabThree={findUserReviewCountThree[0].countvocabthree} countGrammarThree={findUserReviewCountThree[0].countgrammarthree} countVocabFour={0} countGrammarFour={0} countVocabFive={0} countGrammarFive={0} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length > 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length === 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={0} countGrammarOne={0} countVocabTwo={findUserReviewCountTwo[0].countvocabtwo} countGrammarTwo={findUserReviewCountTwo[0].countgrammartwo} countVocabThree={0} countGrammarThree={0} countVocabFour={0} countGrammarFour={0} countVocabFive={0} countGrammarFive={0} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                } else if (findUserReviewCountOne.length > 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length === 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={findUserReviewCountOne[0].countvocabone} countGrammarOne={findUserReviewCountOne[0].countgrammarone} countVocabTwo={0} countGrammarTwo={0} countVocabThree={0} countGrammarThree={0} countVocabFour={0} countGrammarFour={0} countVocabFive={0} countGrammarFive={0} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                }
                //five 
                else if (findUserReviewCountOne.length === 0 && findUserReviewCountTwo.length === 0 && findUserReviewCountThree.length === 0 && findUserReviewCountFour.length === 0 && findUserReviewCountFive.length === 0) {
                    return (
                        <ContentReview user_id={findUserReview[0].user_id} countvocab={findUserReview[0].countvocab} countgrammar={findUserReview[0].countgrammar} countVocabAll={findUserReviewCountAll[0].countvocaball} countGrammarAll={findUserReviewCountAll[0].countgrammarall} countVocabOne={0} countGrammarOne={0} countVocabTwo={0} countGrammarTwo={0} countVocabThree={0} countGrammarThree={0} countVocabFour={0} countGrammarFour={0} countVocabFive={0} countGrammarFive={0} linkToVocab={'/signUpMember'} linkToGrammar={'/signUpMember'} clickNoReview={this.clickNoReview} />
                    )
                }
            } else {
                return <NoReview clickNoReview={this.clickNoReview} />
            }
        } else {
            return <NoReview clickNoReview={this.clickNoReview} />
        }
    }
    clickNoReview = () => {
        this.props.alertDOnSuccess()
    }

    showTimeStudy = () => {
        if (this.props.signUpMember.timeStudyData !== null) {
            const findTimeStudy = this.props.signUpMember.timeStudyData.every(value => value.discount_percen === '0');
            if (findTimeStudy) {
                return this.props.signUpMember.timeStudyData.map((value) => {
                    return (
                        <NoHaveDiscountPercent key={value.timestudy_id} timestudy_name={value.timestudy_name} original_price={value.original_price} />
                    )
                })
            } else {
                return this.props.signUpMember.timeStudyData.map(value => {
                    return (
                        <HaveDiscountPercent key={value.timestudy_id} timestudy_name={value.timestudy_name} original_price={value.original_price} discount_percen={value.discount_percen} />
                    )
                })
            }
        }
    }

    render() {
        const { user } = this.props.auth;
        const { isNavigateLogOut } = this.props.logOut;
        if (isNavigateLogOut) {
            return <Navigate to="/login" />
        }
        else {
            return (
                <main>
                    <MenuHome fullname={user.fullname} logout={this.logOutButton} />
                    <AlertDanger alertContent={<a href='/signUpMember' className='alertSignUpMember'>Click vào đây để đăng ký thành viên mở khóa ôn tập</a>} />
                    {this.showReview(user.user_id)}
                    <InstructionSignUpMember showTimeStudy={this.showTimeStudy()} />
                    <a
                        href="https://m.me/450832184773698"
                        style={{ textDecoration: "none" }}
                        title="Chat với Yukiainn"
                    >
                        <div className="messenger">
                            <i className="fa-brands fa-facebook-messenger" />
                        </div>
                    </a>
                </main>
            );
        }
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        logOut: state.logOut,
        review: state.review,
        study: state.study,
        signUpMember: state.signUpMember
    }
}
const mapDispatchToProps = {
    isAuthUser,
    logOutUser,
    showReview,
    showReviewCountAll,
    showReviewCountOne,
    showReviewCountTwo,
    showReviewCountThree,
    showReviewCountFour,
    showReviewCountFive,
    vocabularyShow,
    grammarShow,
    alertDOnSuccess,
    timeStudy
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)